<template>
  <div class="collection-hero" v-if="collection">
    <div class="inner">
      <div class="top">
        <div class="subheading">Collection —</div>
        <div class="heading">{{collection.elements.name.value}}</div>
        <bleed-container bleed="left">
          <div class="image">
            <img :src="collection.elements.image.value[0].url" :alt="collection.elements.name.value">
          </div>
        </bleed-container>
      </div>
      <div class="bottom">
        <div class="text space-y-8" v-html="collection.elements.description.value"></div>
        <div class="text mt-8" v-if="collection.elements.long_description.value.length > 20"><button class="focus:outline-none hover:underline" @click.prevent="showOverlay = !showOverlay">Read <span v-if="!showOverlay">more</span><span v-if="showOverlay">less</span> &gt;</button></div>
      </div>
    </div>
    <div v-if="showOverlay" class="overlay space-y-8 mt-16" v-html="collection.elements.long_description.value"></div>
  </div>
</template>

<script>
import BleedContainer from "@/components/BleedContainer";
import Bus from '@/events/EventBus';
export default {
  name: 'CollectionHero',
  components: {BleedContainer},
  props: {
    collection: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      showOverlay: false
    }
  },
  methods: {
    showPopup() {
      Bus.$emit('show-register-popup')
    }
  }
}
</script>

<style scoped lang="scss">
.alpaca-link {
  margin-top: 20px;
  font-weight: normal;
}

.collection-hero .inner {
  @media(min-width: 768px)
  {
    box: horizontal bottom;
  }
}
.subheading {
  font-size: 18px;
  font-weight: bold;
  @media(min-width: 768px)
  {
    font-size: 36px;
  }
}
.heading {
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  @media(min-width: 768px)
  {
    font-size: 68px;
  }
  position: relative;
  z-index: 2;
}
.image {
  margin-top: -20px;
  position: relative;
  z-index: 1;

  @media(min-width: 768px)
  {
    margin-top: -60px;
    height: 300px;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media(min-width: 1200px)
  {
    height: 400px;
  }
}
.top {
  @media(min-width: 768px)
  {
    width: 100%;
  }
}
.bottom {
  margin-top: 40px;
  font-size: 14px;
  font-weight: bold;
  button {
    font-size: 14px;
    font-weight: normal;
  }
  @media(min-width: 768px)
  {
    width: 30%;
    margin-left: 30px;
    flex-shrink:0;
    max-width: 370px;
  }

  @media(min-width: 1024px)
  {
    width: 40%;
    margin-left: 60px;
  }
}
</style>
