<template>
  <div>
    <div class="loading flex flex-row items-center justify-center fixed left-0 top-20 md:top-40 lg:top-60 w-full bottom-0 z-100 bg-black transition-all duration-500" :class="[{'invisible opacity-0': collection}]">
      <loading-spinner />
    </div>
    <div class="page min-h-screen">
      <div class="collection" v-if="collection">
        <div class="outer-container">
          <div class="inner-container">
            <div class="breadcrumbs mt-12 text-grey-400 text-12 uppercase">
              <router-link to="/discover" class="hover:text-white transition-colors">Discover</router-link><span class="divider inline-block px-4">&gt;</span><router-link to="/discover/collections" class="hover:text-white transition-colors">Collections</router-link><span class="divider inline-block px-4">&gt;</span><span class="text-white">{{ $route.params.collection }}</span>
            </div>
            <div class="mt-16">
              <collection-hero :collection="collection" />
            </div>
          </div>
        </div>

        <div>
          <div class="heading border-b border-grey-100 py-4 mt-16 md:mt-24" v-if="products.length">
            <div class="outer-container">
              <div class="inner-container">
                <div class="section-heading">Wine Collection —</div>
              </div>
            </div>
          </div>
          <div class="outer-container">
            <div class="inner-container">
              <div class="masonry mt-12">
                <masonry
                    :cols="{default: 4, 1023: 3, 767:2, 400:1}"
                    :gutter="{default: '30px'}"
                >
                  <product-tile v-for="product in products" :key="'product' + product.system.id" :product="product" class="mt-12" />
                </masonry>
              </div>
            </div>
          </div>

          <div class="heading border-b border-grey-100 py-4 mt-16 md:mt-24" v-if="wineries.length">
            <div class="outer-container">
              <div class="inner-container">
                <div class="section-heading">Winery Collection —</div>
              </div>
            </div>
          </div>
          <div class="outer-container">
            <div class="inner-container">
              <div class="masonry mt-12">
                <masonry
                    :cols="{default: 3, 1023: 2, 767: 1}"
                    :gutter="{default: '30px'}"
                >
                  <winery-tile v-for="winery in wineries" :key="'winery' + winery.system.id" :winery="winery" class="mt-12" />
                </masonry>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import CollectionHero from "@/components/CollectionHero";
import Kontent from "@/kontent";
import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'
import WineryTile from "@/components/WineryTile";
import ProductTile from "@/components/ProductTile";
import LoadingSpinner from "@/components/LoadingSpinner";
// import LockedContent from "@/components/LockedContent";
Vue.use(VueMasonry);
export default {
  name: 'DiscoverCollection',
  metaInfo () {
    if (!this.collection) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.collection.elements.page__meta_title.value.length ? this.collection.elements.page__meta_title.value : this.collection.elements.page__page_title.value},
      {property: 'og:description', content: this.collection.elements.page__page_description.value},
      {name: 'title', content: this.collection.elements.page__meta_title.value.length ? this.collection.elements.page__meta_title.value : this.collection.elements.page__page_title.value},
      {name: 'keywords', content: this.collection.elements.page__meta_keywords.value},
      {name: 'description', content: this.collection.elements.page__meta_description.value.length ? this.collection.elements.page__meta_description.value : this.collection.elements.page__page_description.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.collection.elements.page__meta_title.value.length ? this.collection.elements.page__meta_title.value : this.collection.elements.page__page_title.value},
      {name: 'twitter:description', content: this.collection.elements.page__meta_description.value.length ? this.collection.elements.page__meta_description.value : this.collection.elements.page__page_description.value}
    ]

    // if there's an image
    if (this.collection.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.collection.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.collection.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.collection.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.collection.elements.page__page_type.value[0].name})
    }

    return {
      title: this.collection.elements.page__page_title.value,
      meta: meta
    }
  },
  components: {LoadingSpinner, ProductTile, WineryTile, CollectionHero},
  props: {},
  data () {
    return {
      collection: null,
      collectionContent: null
    }
  },
  computed: {
    wineries () {
      let wineries = []
      if (this.collection) {
        for (let i = 0; i < this.collection.elements.wineries.value.length; i++) {
          const w = this.collection.elements.wineries.value[i];
          wineries.push(this.collectionContent[w])
        }
      }
      return wineries
    },
    products () {
      let products = []
      if (this.collection) {
        for (let i = 0; i < this.collection.elements.products.value.length; i++) {
          const w = this.collection.elements.products.value[i];
          products.push(this.collectionContent[w])
        }
      }
      return products
    }
  },
  mounted () {
    Analytics.trackPage('Collection ' + this.$route.params.collection)
    if (this.$auth.isAuthenticated) {
      this.$store.dispatch("getFavouriteProducts")
      this.$store.dispatch("getFavouriteWineries")
    }

    Kontent.getItem('collection', this.$route.params.collection, 1).then((res) => {
      this.collection = res.data.items[0]
      this.collectionContent = res.data.modular_content

      setTimeout(function(){window.prerenderReady = true}, 1000)
    })
  }
}
</script>

<style scoped lang="scss">
.section-heading {
  font-weight: 600;
  font-size: 24px;

  @media(min-width: 768px)
  {
    font-size: 34px;
  }
}
</style>
